/**
 * 咨询记录页面 ConsultingRecord
 * */
import React, { useEffect, useState } from 'react';
import './index.less';
import { message, Spin } from "antd";
import {fromJS} from "immutable";
import ConsultingRecordItem from './ConsultingRecordItem';
import { fetchData } from '../../middleware/fetchData';
import noData from "../../image/noData.png";
let Index  = (props) => {

    const [loading, setLoading] = useState(false);
    const [recordData,setRecordData] = useState(fromJS([]));
    useEffect(() => {
        getConsultingRecordData();
    }, []);

    //展开收起咨询内容
    const handleChangeContentFlag = (data, index) => {
        let newRecord = recordData;
        setRecordData(newRecord.setIn([index, 'contentFlag'], !data.get('contentFlag')));
    };

    //查询咨询记录
    const getConsultingRecordData = () => {
        setLoading(true);
        fetchData(`/his-war/workBenchConsultant/searchCureConsultAndResvByCustomerId`, {
            body: JSON.stringify({
                customerId: sessionStorage.patinenterId || ''
            })
        }).then(res => {
            if (!res) return setLoading(false);
           const { data: { workBenchCustomerConsultInfoRespDTOs }, flag, message } = res;
           if (!!workBenchCustomerConsultInfoRespDTOs && flag) {
               setLoading(false);
               workBenchCustomerConsultInfoRespDTOs.sort((a, b) => {
                   if (a.reservation.resvDay === b.reservation.resvDay) {
                       return a.reservation.resvStartTime < b.reservation.resvStartTime ? 1 : -1;
                   } else {
                       return a.reservation.resvDay < b.reservation.resvDay ? 1 : -1;
                   }
               });
               setRecordData(fromJS(workBenchCustomerConsultInfoRespDTOs || []));
           } else {
              setLoading(false);
              message.error(!!message ? message : '咨询记录加载失败！');
           }
        });
    };
    return (
        <div className='consultingRecord'>
            <Spin spinning={loading}  className='patient-details-spin' >
                {
                    !!recordData && recordData.size > 0 && recordData.map((record, index) => {
                        return (
                            <ConsultingRecordItem
                                record={record}
                                key={index}
                                recordIndex={index}
                                handleChangeContentFlag={handleChangeContentFlag}
                            />
                        )
                    })
                }
                {
                    !loading && (!recordData || !recordData.size) && <div className="noData"> <img src={noData} alt="" /></div>
                }
            </Spin>

        </div>
    )
};
export default Index;