import axios from 'axios';
import { message } from 'antd';

let instance = axios.create();

const deepMerge = (sourceObject, targetObject) => {
    for(let key in targetObject) {
        if(!targetObject.hasOwnProperty(key)) continue;
        sourceObject[key] = sourceObject[key] && sourceObject[key].toString() === '[object Object]' ? deepMerge(sourceObject[key], targetObject[key]) : targetObject[key];
    }
    return sourceObject;
};

instance.interceptors.response.use(
    response => {
        return response.data;
    },
    error => {
        if (error && error.response) {
            if (error.response.status === 401) {
                message.warning('出错了：该客户尚未登记系统' );
                return;
            } else {
                message.warning('出错了：' + error.response.status);
            }
        }
    }
);

export function fetchData(url, params) {
    if(!params){
        return instance(
            window.location.origin + url,
            deepMerge({
                withCredentials: false,
                method: 'get'
            }, params)
        );
    }
    if (params && params.body) {
        params.data = params.body;
        delete params.body;

    }
    const headers = params.headers ? params.headers : {
        'Content-Type': 'application/json',
    };
    return instance(
        window.location.origin + url,
        deepMerge({
            withCredentials: false,
            method: 'post',
            headers: headers
        }, params)
    );
}
