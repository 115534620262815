import React, { useState, useEffect, useRef } from 'react';
import { Drawer } from 'antd-mobile';
import './index.less';

const CommonDrawer = (props) => {
    const { content, onOpenChange } = props;
    return (
        <Drawer
            className="common-drawer"
            style={{ minHeight: document.body.clientHeight }}
            position="bottom"
            sidebar={content}
            open={true}
            onOpenChange={onOpenChange}
        />
    );
};

export default CommonDrawer;