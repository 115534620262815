import { fromJS } from "immutable";
import {
    CHANGE_VISIT_LIST_LOADING,
    CHANGE_VISIT_LIST,
    CHANGE_VISIT_LIST_OPEN_STATUS,
    CHANGE_VISIT_RESULT_CONTENT,
    CHANGE_VISIT_VISIT_RESULT_CONTENT_LOADING,
    RESET_VISIT_LIST
} from "../../actions/returnVisitAction";

export default (
    state = fromJS({
        visitContents: [
            {
                key: 'followUpOrg',
                name: '回访机构'
            },
            {
                key: 'followUpOwner',
                name: '回访人'
            },
            {
                key: 'createTime',
                name: '回访创建时间'
            },
            {
                key: 'planTime',
                name: '计划回访时间'
            },
            {
                key: 'realTime',
                name: '实际回访时间'
            },
            {
                key: 'updater',
                name: '修改人'
            },
            {
                key: 'content',
                name: '回访内容'
            },
            {
                key: 'result',
                name: '回访结果'
            },
        ],
        visitListLoading: true,
        visitList: [],
        visitResultContent: {},
        visitResultContentLoading: true
    }),
    action
) => {
    const { type, data } = action;
    switch (type) {
        case CHANGE_VISIT_LIST_LOADING:
            return state.set('visitListLoading', data);
        case CHANGE_VISIT_VISIT_RESULT_CONTENT_LOADING:
            return state.set('visitResultContentLoading', data);
        case CHANGE_VISIT_LIST:
            return state.set('visitList', state.get('visitList').concat(fromJS(data || [])));
        case CHANGE_VISIT_RESULT_CONTENT:
            return state.set('visitResultContent', fromJS(data || {}));
        case CHANGE_VISIT_LIST_OPEN_STATUS:
            const { cardId, visitKey, openStatus, cardIndex } = action;
            let newVisitList = state.get('visitList');
            if (visitKey === 'content') newVisitList = newVisitList.setIn([cardIndex, 'contentOpenStatus'], openStatus);
            if (visitKey === 'result') newVisitList = newVisitList.setIn([cardIndex, 'resultOpenStatus'], openStatus);
            return state.set('visitList', newVisitList);
        case RESET_VISIT_LIST:
            return state.set('visitList', fromJS([]));
        default:
            return state;
    }
};