import { message } from 'antd';
import { fetchData } from '../../middleware/fetchData';
import { fromJS } from 'immutable';

export const GET_INIT_DATA = 'GET_INIT_DATA';
export const CUSTOMER_DETAIL_FOLLOWED_ADD_INIT_SUCC = 'CUSTOMER_DETAIL_FOLLOWED_ADD_INIT_SUCC';

// 获取回访列表数据
export const getInitData = () => dispatch => {
    fetchData(`/his-war/login/loginInfo`, {
        body: JSON.stringify({})
    }).then(res => {
        if (res && res.data) {
            const { data = {} } = res;
            dispatch({
                type: GET_INIT_DATA,
                data: fromJS(data)
            });
        } else {
            message.info('出错了' + (res ? ':' + res.message : ''));
        }
    });
};

/**
 * 初始化
 * @returns {function()}
 */
export function init(reqParams) {
    return (dispatch) => {
        fetchData('/his-war/customerFollowUp/queryInsertInit.do', {
            body: JSON.stringify(reqParams)
        }).then((res) => {
            let flag = res.flag;
            if (typeof flag !== 'undefined' && flag === 1) {
                dispatch({
                    type: CUSTOMER_DETAIL_FOLLOWED_ADD_INIT_SUCC,
                    data: res.data || []
                });
            } else {
                message.info('出错了' + (res ? ':' + res.message : ''));
            }
        });
    };
}
