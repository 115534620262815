/**
 * 咨询记录item consultingRecordItem
 * */
import React, { Fragment } from 'react';
import moment from "moment";

let ConsultingRecordItem = (props) => {

    const {record, recordIndex, handleChangeContentFlag } = props;

    const showRecordContent = (record, recordIndex) => {
        if ( record.get('contentFlag') ) {
            return (
                <Fragment>
                    {record.get('content')}
                    <span className='flagcolor' onClick={() => handleChangeContentFlag(record, recordIndex)}>收起</span>
                </Fragment>

            );
        } else {
            if ( record.get('content').length > 26 ) {
                return (
                    <Fragment>
                        {record.get('content').substring(0, 26) + '...'}
                        <span className='flagcolor' onClick={() => handleChangeContentFlag(record, recordIndex)}>展开</span>
                    </Fragment>
                )
            } else {
                return record.get('content');
            }
        }
    };

    return (
        <div className="consultingRecordItem">
            <div className="timeAndType">
                <div className="time">
                    {moment(record.getIn(['reservation', "resvDay"])).format("YYYY-MM-DD")}
                    {`  ${record.getIn(['reservation', "resvStartTime"])}-${record.getIn(['reservation', "resvEndTime"])}`}

                    {record.getIn(["reservation", "typeCode"]) ===
                    "FIRST_DIAGNOSIS" && (
                        <span
                            className='typeCode firstBg'>
                            初
                        </span>
                    )}
                    {record.getIn(["reservation", "typeCode"]) ===
                    "RETURN_DIAGNOSIS" && (
                        <span
                            className='typeCode complexBg'>
                            复
                        </span>
                    )}
                </div>

                <span
                    className={`type ${record.getIn(["resultCode", "itemCode"]) === 'RESULT_YES' ? 'alreadyComplete' : 'notComplete'}`}>
                   {record.getIn(["resultCode", "itemValue"])}
                </span>
            </div>

            <div className="itemLine">
                <span className="itemLineBefore">
                    咨询机构
                </span>
                <div className="itemLineAfter">
                    {record.getIn(["ownerOrgId", "name"])}
                </div>
            </div>
            <div className="itemLine">
                <span className="itemLineBefore">
                    咨询人员
                </span>
                <div className="itemLineAfter">
                    {record.getIn(["ownerId", "name"])}
                </div>
            </div>
            <div className="itemLine">
                <span className="itemLineBefore">
                    咨询项目
                </span>
                <div className="itemLineAfter">
                    {record.get('productNames')}
                </div>
            </div>
            <div className="itemLine">
                <span className="itemLineBefore">
                    咨询内容
                </span>
                <div className='itemLineAfter'>
                    {showRecordContent(record, recordIndex)}
                </div>
            </div>
            {record.getIn(["resultCode", "itemCode"]) === 'RESULT_NO' && (
                <div className='itemLine'>
                     <span className="itemLineBefore">
                        未成交原因
                    </span>
                    <div className="itemLineAfter">
                        {record.getIn(['lossType','itemValue'])}
                    </div>
                </div>
            )}
        </div>
    )
};
export default ConsultingRecordItem;