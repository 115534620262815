import { Spin, Button } from 'antd';
import { ListView } from 'antd-mobile';
import { fromJS } from 'immutable';
import moment from 'moment';
import React, { Fragment, useEffect, useState } from 'react';
import { useSelector, useDispatch, shallowEqual, connect } from 'react-redux';
import { CHANGE_VISIT_LIST_OPEN_STATUS, changeLoading, resetVisitList, getVisitList, updateStatus } from '../../actions/returnVisitAction';
import './index.less';
import noData from '../../image/noData.png';

let ReturnVisit = (props) => {

    const { history, getVisitList, changeLoading, resetVisitList, updateStatus } = props;
    const visitState = useSelector(state => state.returnVisit, shallowEqual);
    const visitList = visitState.get('visitList');
    const visitContents = visitState.get('visitContents');
    const visitListLoading = visitState.get('visitListLoading');
    const dispatch = useDispatch();
    const [currentTotal, setCurrentTotalTotal] = useState(0);
    const [pagination, setPagination] = useState(
        fromJS({
            current: 1,
            total: 0,
            pageSize: 10
        })
    );

    useEffect(() => {
        if (visitListLoading) {
            getVisitList(pagination.get('current'), pagination.get('pageSize'), (current, pageSize, total) => {
                setPagination(
                    fromJS({
                        current,
                        pageSize,
                        total
                    })
                );
                setCurrentTotalTotal(currentTotal + Number(visitList.size || 0));
            });
        }
    }, [visitListLoading]);

    useEffect(() => {
        return () => {
            changeLoading(true);
            resetVisitList();
        };
    }, []);

    // 展开，收起
    const handleOpenWord = (cardId, cardIndex, visitKey, openStatus) => {
        dispatch({
            type: CHANGE_VISIT_LIST_OPEN_STATUS,
            openStatus,
            cardId,
            cardIndex,
            visitKey
        });
    };

    // 新建回访
    const gotoAddReturnVisit = () => {
        history.push({
            pathname: '/patientInfomation/returnVisit/addReturnVisit'
        });
    };

    // 新建临时回访
    const gotoAddTemporaryReturnVisi = () => {
        history.push({
            pathname: '/patientInfomation/returnVisit/temporaryReturnVisit/add'
        });
    };

    // 进入回访页面，编辑回访内容
    const goFillVisitContent = (customerFollowUpId) => {
        history.push({
            pathname: `/patientInfomation/returnVisit/temporaryReturnVisit/edit?customerFollowUpId=${customerFollowUpId}`
        });
    };

    // 取消回访
    const handleCancelVisit = (customerFollowUpId, statusCode) => {
        resetVisitList();
        updateStatus(customerFollowUpId, 'CANCEL');
    };

    // 滑动分页
    let onEndReached = () => {
        if (pagination.get('total') <= visitList.size) {
            console.log('已加载完全部数据～');
            return;
        }
        let paginationNew = pagination.set('current', pagination.get('current') + 1);
        setPagination(paginationNew);
        changeLoading(true);
    };

    const handleVisitContent = (cardItem, cardIndex, itemKey) => {
        if (itemKey === 'createTime') return cardItem.get(itemKey) ? moment(cardItem.get(itemKey)).format('YYYY-MM-DD HH:mm:ss') : '-';
        if (itemKey === 'planTime') return cardItem.get(itemKey) ? moment(cardItem.get(itemKey)).format('YYYY-MM-DD HH:mm:ss') : '-';
        if (itemKey === 'realTime') return cardItem.get(itemKey) ? moment(cardItem.get(itemKey)).format('YYYY-MM-DD HH:mm:ss') : '-';
        if (itemKey === 'content' || itemKey === 'result') {
            let contentOpenStatus = itemKey === 'content' && !!cardItem.get('contentOpenStatus');
            let resultOpenStatus = itemKey === 'result' && !!cardItem.get('resultOpenStatus');
            if (contentOpenStatus || resultOpenStatus) {
                return (
                    <Fragment>
                        {cardItem.get(itemKey)}
                        <span className="openWord" onClick={() => handleOpenWord(cardItem.get('id'), cardIndex, itemKey, false)}>收起</span>
                    </Fragment>
                )
            } else {
                if (cardItem.get(itemKey) && cardItem.get(itemKey).length > 26) {
                    return (
                        <Fragment>
                            {cardItem.get(itemKey).substring(0, 26) + '...'}
                            <span className="openWord" onClick={() => handleOpenWord(cardItem.get('id'), cardIndex, itemKey, true)}>展开</span>
                        </Fragment>
                    )
                } else {
                    return cardItem.get(itemKey) || '-';
                }
            }
        }
        if (itemKey === 'followUpOrg' || itemKey === 'followUpOwner') return cardItem.getIn([itemKey, 'name']) || '-';
        return cardItem.get(itemKey) || '-';
    };

    const row = (rowData, sectionID, rowID) => { };

    const dataSource = new ListView.DataSource({
        rowHasChanged: (row1, row2) => row1 !== row2,
        sectionHeaderHasChanged: (s1, s2) => s1 !== s2,
    });

    return (
        <div className="visitList">
            <div className="operations">
                <Button className="btn" shape="round" type="primary" ghost onClick={gotoAddReturnVisit} style={{ marginRight: '0.08rem' }}>新建回访计划</Button>
                <Button className="btn" shape="round" type="primary" ghost onClick={gotoAddTemporaryReturnVisi}>新建临时回访</Button>
            </div>
            <Spin spinning={visitListLoading}>
                <Fragment>
                    {
                        visitList.size ? <ListView
                            dataSource={dataSource}
                            renderRow={row}
                            style={{
                                height: 'calc(100vh - 2.5rem)',
                                overflow: 'auto',
                            }}
                            onEndReached={e => onEndReached(e)}
                        >
                            <div className="visitList">
                                {
                                    visitList.map((cardItem, cardIndex) => {
                                        const status = cardItem.getIn(['statusCode', 'itemCode']);
                                        return (
                                            <div className="card">
                                                <div className="card-header">
                                                    <span className="name">{cardItem.getIn(['typeCode', 'itemValue'])}</span>
                                                    <span className={status === 'FOLLOW' ? 'follow' : status === 'CANCEL' ? 'cancel' : 'notFollow'}>{cardItem.getIn(['statusCode', 'itemValue'])}</span>
                                                </div>
                                                <div className="card-body">
                                                    {
                                                        visitContents.map(item => {
                                                            return (
                                                                <div className="content-item">
                                                                    <span className="visitKey">{item.get('name')}</span>
                                                                    <span className="visitContent">{handleVisitContent(cardItem, cardIndex, item.get('key'))}</span>
                                                                </div>
                                                            );
                                                        })
                                                    }
                                                </div>
                                                {status === 'NOT_FOLLOW' && <div className="card-bottom">
                                                    <Button className='btn submit-btn' type="primary" onClick={() => goFillVisitContent(cardItem.get('customerFollowUpId'))}>回访</Button>
                                                    <Button className="btn" onClick={() => handleCancelVisit(cardItem.get('customerFollowUpId'), cardItem.getIn(['statusCode', 'itemCode']))}>取消</Button>
                                                </div>}
                                            </div>
                                        );
                                    })
                                }
                            </div>
                        </ListView> : <div className="noData"> <img src={noData} alt="" /></div>
                    }
                </Fragment>
            </Spin>

        </div>
    );
};
ReturnVisit = connect(
    () => { },
    {
        getVisitList,
        changeLoading,
        resetVisitList,
        updateStatus
    }
)(ReturnVisit);
export default ReturnVisit;