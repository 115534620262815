import React, { useEffect, useState } from 'react';
import { Button, message } from 'antd';
import moment from 'moment';
import { fromJS } from 'immutable';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { List, Picker, DatePicker, TextareaItem } from 'antd-mobile';
import { insertFollowUp } from '../../actions/returnVisitAction';
import './addReturnVisit.less';

const AddReturnVisit = props => {
    const { history } = props;
    const dispatch = useDispatch();
    const hisMainState = useSelector(state => state.patientInfo, shallowEqual);
    const typeCodes = hisMainState.getIn(['dictMap', 'HIS_FOLLOW_UP_TYPE']) || fromJS([]);
    const userList = hisMainState.get('userList') || fromJS([]);
    const contentList = hisMainState.get('contentList') || fromJS([]);
    const [typeContentList, setTypeContentList] = useState(fromJS([]));
    const [newPlanValue, setNewPlanValue] = useState({ planTime: new Date(Date.now()) });
    const [saveBtnLoading, setSaveBtnLoading] = useState(false);

    // 取消
    const goback = () => {
        history.push({
            pathname: '/patientInfomation/returnVisit'
        });
    };

    // 保存
    const handleSave = () => {
        const { typeCode, followUpOwnerId, planTime, content } = newPlanValue;
        if (!typeCode) {
            message.error('回访类型不能为空！', 1);
            return;
        }
        if (!followUpOwnerId) {
            message.error('回访人不能为空！', 1);
            return;
        }
        if (!planTime) {
            message.error('回访时间不能为空！', 1);
            return;
        }
        if (!content) {
            message.error('回访内容不能为空！', 1);
            return;
        }
        setSaveBtnLoading(true);
        dispatch(insertFollowUp({ ...newPlanValue, planTime: moment(planTime).format('YYYY-MM-DD HH:mm:ss') }, () => {
            message.success('新建回访计划成功！', 1);
            history.push({
                pathname: '/patientInfomation/returnVisit'
            });
            setSaveBtnLoading(false);
        }));
    };

    useEffect(() => {
        if (newPlanValue.typeCode) {
            let newTypeContentList = contentList.filter(
                (value) => value.getIn(['typeCode', 'itemCode']) === newPlanValue.typeCode
            );
            setTypeContentList(newTypeContentList);
        }
    }, [newPlanValue.typeCode]);

    useEffect(() => {
        if (newPlanValue.contentId) {
            let content = '';
            let temp = contentList.find((opt) => opt.get('id') + '' === newPlanValue.contentId);
            content = temp.get('content') + '';
            setNewPlanValue({ ...newPlanValue, content });
        }
    }, [newPlanValue.contentId]);

    return (
        <div className="addReturnVisit">
            <div className="title">新建回访计划</div>
            <List className="addList">
                <Picker value={[newPlanValue.typeCode]} onChange={value => setNewPlanValue({ ...newPlanValue, typeCode: value[0] })} data={typeCodes.toJS().map(item => ({ value: item.itemCode + '', label: item.itemValue + '' }))} cols={1} className="forss" extra="请选择回访类型" >
                    <List.Item arrow="horizontal"><span className="visitKey must">回访类型</span></List.Item>
                </Picker>
                <Picker value={[newPlanValue.followUpOwnerId]} onChange={value => setNewPlanValue({ ...newPlanValue, followUpOwnerId: value[0] })} data={userList.toJS().map(item => ({ value: item.userId + '', label: item.name + '' }))} cols={1} className="forss" extra="请选择回访人">
                    <List.Item arrow="horizontal"><span className="visitKey must">回访人</span></List.Item>
                </Picker>
                <Picker value={[newPlanValue.contentId]} onChange={value => setNewPlanValue({ ...newPlanValue, contentId: value[0] })} data={typeContentList.toJS().map(item => ({ value: item.id + '', label: item.content + '' }))} cols={1} className="forss" extra="请选择内容模板">
                    <List.Item arrow="horizontal"><span className="visitKey">内容模板</span></List.Item>
                </Picker>
                <DatePicker
                    value={newPlanValue.planTime}
                    onChange={value => setNewPlanValue({ ...newPlanValue, planTime: value })}
                    extra="请选择回访时间"
                >
                    <List.Item arrow="horizontal"><span className="visitKey must">回访时间</span></List.Item>
                </DatePicker>
                <TextareaItem
                    value={newPlanValue.content}
                    onChange={value => setNewPlanValue({ ...newPlanValue, content: value })}
                    title={<span className="visitKey must">回访内容</span>}
                    placeholder="请输入回访内容"
                    data-seed="logId"
                    autoHeight
                ></TextareaItem>
            </List>
            <div className="bottom-button">
                <Button
                    onClick={goback}
                    className="info-button back"
                >取消</Button>
                <Button
                    loading={saveBtnLoading}
                    onClick={handleSave}
                    className="info-button save"
                    type="primary"
                >保存</Button>
            </div>
        </div >
    )
}
export default AddReturnVisit;
